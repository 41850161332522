import React, { useState, useEffect, useContext, useRef } from 'react';
import { API_URL } from '../services/api';
import LoginModal from '../components/LoginModal';
import AccountModal from '../components/AccountModal';
import { AuthContext } from '../contexts/AuthContext';
import Container from '../components/Container';
import { useLocation } from 'react-router-dom';
import './Header.css';

import logo from '../assets/logo-dealio.svg';
import loginIcon from '../assets/login_icon.svg';
import newDealIcon from '../assets/global-icons/add.svg';

function Header() {
    const { isAuthenticated, isModalOpen, toggleModal, userName, logout } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [lastScrollY, setLastScrollY] = useState(0);
    const [headerVisible, setHeaderVisible] = useState(true);
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [accountButtonPosition, setAccountButtonPosition] = useState({ top: 0, left: 0 });
    const location = useLocation();
    const userButtonRef = useRef(null);

    const updateAccountButtonPosition = () => {
        if (userButtonRef.current) {
            const rect = userButtonRef.current.getBoundingClientRect();
            setAccountButtonPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            });
        }
    };

    const handleAccountFocus = () => {
        setIsAccountModalOpen(!isAccountModalOpen);
        updateAccountButtonPosition();
    };

    const handleLogoClick = (e) => {
        e.preventDefault();
        window.location.href = '/';
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            window.location.href = `/search?name=${encodeURIComponent(searchQuery)}`;
        }
    };

    const isOnHomePage = location.pathname === '/';

    useEffect(() => {
        const handleScroll = () => {
            if (isOnHomePage) {
                if (window.scrollY > lastScrollY) {
                    setHeaderVisible(false);
                } else {
                    setHeaderVisible(true);
                }
                setLastScrollY(window.scrollY);
            } else {
                setHeaderVisible(true);
            }
        };

        const handleResize = () => {
            if (isAccountModalOpen) {
                updateAccountButtonPosition();
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [lastScrollY, isOnHomePage, isAccountModalOpen]);

    const getInitials = (name) => {
        return name ? name.charAt(0).toUpperCase() : '';
    };

    return (
        <div className="header__header-space">
            <header className={`header ${headerVisible ? 'header--visible' : 'header--hidden'}`}>
                <Container isMarginlessOnSmallScreen={true} isHeader={true}>
                    <div className="header__content">
                        <div className="header__logo" onClick={handleLogoClick}>
                            <a href="/">
                                <img src={logo} alt="Logo" width="50" />
                            </a>
                        </div>

                        <form className="header__search-form" onSubmit={handleSearchSubmit}>
                            <input
                                type="text"
                                className="header__search-input"
                                placeholder="Search deals..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </form>

                        {isOnHomePage && (
                            <button
                                className="header__new-deal-button--bigScreen"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (isAuthenticated) {
                                        window.location.href = '/newdeal';
                                    } else {
                                        toggleModal(true);
                                    }
                                }}>
                                <img src={newDealIcon} alt="New Deal" className="header__icon" />
                                Add Deal
                            </button>
                        )}

                        <div className="header__user-actions">
                            {isAuthenticated ? (
                                <>
                                    <button
                                        className="header__logout-button"
                                        onClick={handleAccountFocus}
                                        ref={userButtonRef}
                                    >
                                        {getInitials(userName)}
                                    </button>
                                    {isAccountModalOpen && (
                                        <AccountModal
                                            onClose={handleAccountFocus}
                                            position={accountButtonPosition}
                                        />
                                    )}
                                </>
                            ) : (
                                <button className="header__login-button" onClick={toggleModal}>
                                    <img src={loginIcon} alt="Login" className="header__icon" />
                                    Connect
                                </button>
                            )}
                        </div>
                    </div>
                </Container>
                {(!isAuthenticated && isModalOpen) && <LoginModal onClose={toggleModal} />}
            </header>
            {isOnHomePage && (
                <button
                    className="header__new-deal-button--smallScreen"
                    onClick={(e) => {
                        e.preventDefault();
                        if (isAuthenticated) {
                            window.location.href = '/newdeal';
                        } else {
                            toggleModal(true);
                        }
                    }}>
                    <img src={newDealIcon} alt="New Deal" className="header__newDeal-icon--smallScreen" />
                </button>
            )}
        </div>
    );
}

export default Header;