import React, { useState, useContext, useRef } from 'react';
import apiService from '../services/api';
import './ReportButton.css';
import { AuthContext } from '../contexts/AuthContext';

import closeIcon from '../assets/global-icons/close.svg';

function ReportButton({ dealId, userReportType, alertPopup, onReportSubmit }) {
    const { isAuthenticated, toggleModal } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');
    const [reportType, setReportType] = useState('');
    const [step, setStep] = useState(1); // Step 1 for selecting report type, Step 3 for entering comment
    const [errorMessageMaxLenght, setErrorMessageMaxLenght] = useState('');
    const [errorMessageMinLenght, setErrorMessageMinLenght] = useState('');
    const MAX_COMMENT_LENGTH = 1000; // Define maximum comment length
    const buttonRef = useRef(null); // Reference to the button

    const handleOpenModal = async () => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }

        if (userReportType !== null) {
            alertPopup(`You already reported this deal`, 'Error');
            // Add shake animation
            buttonRef.current.classList.add('shake');
            setTimeout(() => {
                buttonRef.current.classList.remove('shake');
            }, 500);
            return;
        }

        try {
            setShowModal(true);
            setStep(1);
            setComment('');
            setReportType('');
        } catch (error) {
            alertPopup(error.message, 'Error');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setComment('');
        setReportType('');
        setErrorMessageMaxLenght(''); // Clear any previous error message
        setErrorMessageMinLenght(''); // Clear any previous error message
    };

    const handleSelectReportType = (type) => {
        setReportType(type);
        setStep(3); // Move to comment step
    };

    const handleCommentChange = (e) => {
        const newComment = e.target.value;
        if (newComment.length > MAX_COMMENT_LENGTH) {
            setErrorMessageMaxLenght(`Comment is too long. Maximum length is ${MAX_COMMENT_LENGTH} characters.`);
        } else if (newComment.length < 1) {
            setErrorMessageMinLenght(`We need a comment to evaluate the report.`);
        } else {
            setErrorMessageMinLenght(``);
        }
        setComment(newComment);
    };

    const handleSubmit = async () => {
        if (reportType !== 'Expired' && comment.trim() === '') {
            setErrorMessageMinLenght(`We need a comment to evaluate the report.`);
            return;
        }

        if (comment.length > MAX_COMMENT_LENGTH) {
            return;
        }

        try {
            await apiService.submitReport(dealId, reportType, comment);
            handleCloseModal();
            alertPopup(`Your report has been recorded and will be reviewed shortly`, 'success');
            onReportSubmit(); // Call the onReportSubmit method passed from DealPage
        } catch (error) {
            alertPopup(error.message, 'Error');
        }
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    const handleExpiredReport = async () => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }

        try {
            await apiService.submitReport(dealId, "Expired", "");
            alertPopup(`Your report has been recorded and will be reviewed shortly`, 'success');
            onReportSubmit(); // Call the onReportSubmit method passed from DealPage
        } catch (error) {
            alertPopup(error.message, 'Error');
        }
    };

    return (
        <div className="report-button__container">
            <button 
                ref={buttonRef} // Attach the ref to the button
                className={`report-button__trigger ${userReportType !== null ? 'reported' : ''}`} 
                onClick={handleOpenModal} 
            >
                {userReportType !== null ? 'Already reported' : 'Report'}
            </button>
            {userReportType === null && (
                <button 
                    className={`report-button__trigger`} 
                    onClick={handleExpiredReport}
                >
                    Deal expired
                </button>
            )}
            {showModal && (
                <div className="report-button__modal" onClick={handleBackgroundClick}>
                    <div className={`report-button__modal-content ${step === 3 ? 'report-button__modal-content--expanded' : ''}`}>
                        <div className="report-button__modal-header">
                            <h2>Report Deal</h2>
                            <img src={closeIcon} alt="Close" className="report-button__close" onClick={handleCloseModal} />
                        </div>
                        {step === 1 && (
                            <div className="report-button__options">
                                <button className="report-button__option" onClick={() => handleSelectReportType('Spam')}>Spam</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('This deal already exists')}>This deal already exists</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Missing information')}>Missing information</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Counterfeit product')}>Counterfeit product</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Inappropriate/Insulting/Sexual')}>Inappropriate/Insulting/Sexual</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Limited Offer/Not available globally')}>Limited Offer/Not available globally</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Not the best available deal')}>Not the best available deal</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Wrong category')}>Wrong category</button>
                                <button className="report-button__option" onClick={() => handleSelectReportType('Other')}>Other</button>
                            </div>
                        )}
                        {step === 3 && (
                            <>
                                {errorMessageMaxLenght && <p className="report-button__error-message">{errorMessageMaxLenght}</p>}
                                {errorMessageMinLenght && <p className="report-button__error-message">{errorMessageMinLenght}</p>}
                                <textarea
                                    className="report-button__textarea"
                                    placeholder="Enter your comment"
                                    value={comment}
                                    onChange={handleCommentChange}
                                ></textarea>
                                <button onClick={handleSubmit} className="report-button__trigger">Send</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReportButton;