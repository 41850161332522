// components/LoginModal.js

import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import './LoginModal.css';
import GoogleLogo from '../assets/google-logo.svg';
import FacebookLogo from '../assets/facebook-logo.svg';
import ZaloLogo from '../assets/zalo-logo.svg'; // Add Zalo logo here
import { API_URL } from '../services/api';
import closeIcon from '../assets/global-icons/close.svg';

function LoginModal({ onClose }) {
    const { login, isAuthenticated } = useContext(AuthContext);

    // Handle authentication success
    useEffect(() => {
        if (isAuthenticated) {
            onClose(); // Close modal
        }
    }, [isAuthenticated, onClose]);

    // Function to create the authentication URL with a return path
    const createAuthUrl = (authType) => {
        const currentPath = window.location.pathname || '/'; // Fallback to root if path is empty
        console.log(`Auth URL: ${API_URL}auth/${authType}?returnPath=${encodeURIComponent(currentPath)}`); // Log the URL
        return `${API_URL}auth/${authType}?returnPath=${encodeURIComponent(currentPath)}`;
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="login-modal" onClick={handleBackgroundClick}>
            <div className="login-modal__content">
                <div className="login-modal__header">
                    <h2 className="login-modal__title">Log in to Dealio</h2>
                    <img src={closeIcon} alt="Close" className="login-modal__close" onClick={onClose} />
                </div>
                {/* Modal content */}
                <div className="login-modal__auth-buttons">
                    <button onClick={() => window.location.href = createAuthUrl('google')} className="login-modal__auth-button login-modal__auth-button--google">
                        <img src={GoogleLogo} alt="Google" className="login-modal__auth-icon" />
                        GOOGLE
                    </button>
                    <button onClick={() => window.location.href = createAuthUrl('facebook')} className="login-modal__auth-button login-modal__auth-button--facebook">
                        <img src={FacebookLogo} alt="Facebook" className="login-modal__auth-icon" />
                        FACEBOOK
                    </button>
                    <button onClick={() => window.location.href = createAuthUrl('zalo')} className="login-modal__auth-button login-modal__auth-button--zalo">
                        <img src={ZaloLogo} alt="Zalo" className="login-modal__auth-icon" />
                        ZALO
                    </button>
                </div>
                <div className="login-modal__terms">
                    By connecting I agree to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>.
                </div>
            </div>
        </div>
    );
}

export default LoginModal;